<template>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol md="7">
                      <h4> WeChat Pending</h4>
                </CCol>              
                <CCol md="5">
                     <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
                </CCol>              
            </CRow>
          </CCardHeader><br/>
          <CCardBody> 
  
           <CRow>
              <CCol col="12" class="text-left">                   
                 <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
           </CRow>  
  
           <div class="text-center">
              <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
           </div>
  
           <b-card-group deck class="mb-3" style="width:60%" v-if="formReason">  
              <b-card border-variant="dark" header="Transaction Reason Action" >
                  <b-card-text>
                      <CTextarea
                          label="Reason"
                          v-model="reason"  
                          placeholder="Type Reason..."
                          maxlength="180"  
                          description="Maximum 180 characters"
                          horizontal
                          rows="3"               
                      />
                  </b-card-text>                                                                      
                  <b-button-group>
                      <b-button v-on:click="actionCancel()">Cancel</b-button>
                      <b-button variant="primary" v-on:click="ActionSubmit()">Submit</b-button>
                  </b-button-group>                                   
              </b-card>
           </b-card-group>
  
  
          <div class="table-responsive scroll-r">
            <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="createDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter"> 
  
                <template v-slot:cell(createDate)="{ item }"> 
                  {{ item.createDate | dateWithTime }}
                </template>
  
                <template v-slot:cell(exchangeRate)="{ item }"> 
                  {{ item.exchangeRate | formatNum }}
                </template>
  
                <template v-slot:cell(senderAmount)="{ item }"> 
                  {{ item.senderAmount | formatNum }}
                </template>
  
                <template v-slot:cell(receiverAmount)="{ item }"> 
                  {{ item.receiverAmount | formatNum }}
                </template>  
                
                <template v-slot:cell(storeBalance)="{ item }"> 
                  {{ item.storeBalance | formatNum }}
                </template>
  
                <template v-slot:cell(transactionFee)="{ item }"> 
                  {{ item.transactionFee | formatNum }}
                </template>
  
                <template v-slot:cell(userDebitedAmount)="{ item }"> 
                  {{ item.userDebitedAmount | formatNum }}
                </template>
  
                <template v-slot:cell(actions)="{ item }"> 
                  <b-dropdown variant="info" text="Actions" size="md"> 
                    <router-link to="#" exact v-on:click.native="showFormAction(item.transactionRef,'cancel')" tag="b-dropdown-item" > Cancel </router-link>
                    <router-link to="#" exact v-on:click.native="showFormAction(item.transactionRef,'complete')" tag="b-dropdown-item" > Complete </router-link>
                  </b-dropdown>
                </template>
  
            </b-table>
          </div>
  
          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>      
          </div>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </template>
  
  <script>
  import TransactService from '@/api/TransactService.js';
  
   export default {
      name: "wechat_pending",
      components: {},     
      data() {
        return {
          spinner:false,
          formReason:false,
          transactionRef:null,
          reason:null,
          action:null,
          list:[],
          columns : [],
          data : [],
          items:[],             
          fields: [ 
            { key: 'createDate', label : 'Date Time', tdClass:'limTDClass', sortable: true },
            { key: 'transactionRef', label : 'Tr Ref', tdClass:'limTDClass', sortable: true },
            { key: 'trsListingId', label : 'Trs Listing ID', tdClass:'limTDClass', sortable: true },               
            { key: 'userIdentify', label : 'User Identify', tdClass: 'limTDClass', sortable: true },  
            { key: 'mobileNumber', label : 'Mobile Number', sortable: true },
            { key: 'userName', label : 'User Name', sortable: true }, 
            { key: 'senderAmount', label : 'Sender Amount', sortable: true },
            { key: 'senderCurrency', label : 'Sender Currency', sortable: true },
            { key: 'walletId', label : 'Wallet ID', tdClass: 'limTDClass', sortable: true },
            { key: 'namescreeningStatus', label : 'Screening Status', tdClass: 'limTDClass', sortable: true },
            { key: 'promoCode', label : 'Promo Code', sortable: true },
            { key: 'promoDiscountAmount', label : 'Promo Discount Amount', sortable: true },
            { key: 'transactionFee', label : 'Tr Fee', sortable: true },
            { key: 'storeBalance', label : 'Store Balance', sortable: true },
            { key: 'userDebitedAmount', label : 'User Debited Amount', sortable: true },
            { key: 'receiverMobile', label : 'Receiver Mobile', sortable: true },
            { key: 'receiverName', label : 'Receiver Name', sortable: true },
            { key: 'receiverCurrency', label : 'Receiver Currency', sortable: true },
            { key: 'receiverAmount', label : 'Receiver Amount', sortable: true }, 
            { key: 'receiverRelationship', label : 'Receiver Relation', sortable: true }, 
            { key: 'exchangeRate', label : 'Exchange Rate', sortable: true }, 
            { key: 'purpose', label : 'Purpose', sortable: true },
            // { key: 'message', label : 'Message', sortable: true },
            { key: 'actions', label: 'Actions' }                   
          ],         
          currentPage: 1,
          perPage: 10,
          filter: null,
          seen:false,
          msg :'',
          color:''
        };
      },    
      filters: {
          upper(value){
            if(!value) return ''
            value = value.toString()
            return value.toUpperCase()
          },
          lower(value){
             if(!value) return ''
             value = value.toString()
             return value.toLowerCase()        
          },
          dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,10)            
          },        
          IdSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,5) + '...'            
          },
          dateWithTime(value){
             if(!value) return ''
             var temp = value.toString().split(".");
             value = temp[0].replace("T"," ");
             return value;            
          },
          formatNum(value){ 
             if(!value) return 0  
             return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
          }
      },  
      created () { 
         this.getWeChatPendingList();  
      },
      methods: { 
  
        getFilterData(){          
           this.data  = this.list;
        }, 
  
        actionCancel(){
          this.action= null; 
          this.transactionRef= null; 
          this.reason = null; 
          this.formReason = false; 
        },
        
        showFormAction(id,action){
          this.action = action;
          this.transactionRef = id;
          this.formReason = true;
        },
  
        ActionSubmit(){
          let params = {}; var validate = [];       
          params["transactionRef"] = this.transactionRef;         
          params["reason"]  =  this.reason;
  
          for (var key in params) {
            if(!params[key]) { validate.push(false); } else { validate.push(true); }
          } 
          
          if(!validate.includes(false)){
              if (confirm("Are you sure want to "+this.action+" WeChat Transaction ?")) {
                  if(this.action === "cancel"){                      
                      this.cancelWeChatPendingList(params); 
                  } else if(this.action === "complete"){
                      this.completeWeChatPendingList(params);
                  } else {
                     this.msg   = 'Something wrong, please check the process !'; 
                     this.color = 'warning'; this.seen  = true;
                     setTimeout( () =>  this.seen=false , 5000);
                  }
              } 
          } else {          
             this.msg   = 'Entry Reason Required!'; 
             this.color = 'warning'; this.seen  = true;
             setTimeout( () =>  this.seen=false , 5000);
          }
        },
  
        cancelWeChatPendingList: function(params) {
          TransactService.cancelWeChatPendingList(params).then(resp => {
              if(resp.message==="Success"){ alert("Action Cancel Success!"); 
                this.actionCancel(); this.getWeChatPendingList(); 
              } else {
                this.msg   =  !resp.data.message ? "Action Cancel Failed!" : resp.data.message; 
                this.color = 'danger'; this.seen  = true;              
              } 
              setTimeout( () =>  this.seen=false , 5000);         
          }, error => {
            this.loading = false;
          });
        },
  
        completeWeChatPendingList: function(params) {
          TransactService.completeWeChatPendingList(params).then(resp => {
              if(resp.message==="Success"){ alert("Action Complete Success!");
                this.actionCancel(); this.getWeChatPendingList();
              } else {
                this.msg   =  !resp.data.message ? "Action Complete Failed!" : resp.data.message; 
                this.color = 'danger'; this.seen  = true;              
              }
              setTimeout( () =>  this.seen=false , 5000);    
          }, error => {
            this.loading = false;
          });
        },

        getWeChatPendingList: function() {
           TransactService.getWeChatPendingList().then(resp => { //console.log(resp);
              this.items = resp;
           }, error => { this.loading = false; });
        }   
      }
   }; 
  
  </script>  
  <style>      
      .limTDClass { max-width: 150px; }
  </style> 